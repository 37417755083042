<template>
  <div :class="isMobile ? 'dashboard-mobile' : 'dashboard'">
    <!-- side bar -->
    <div v-if="!isMobile" class="dashboard__sidebar">
      <CustomMenu in-dashboard />
    </div>
    <DashboardNavbar
      @menuClick="setMenuVisibility(true)"
      @setLoading="setLoading"
      @setChange="setHeadMasterCurrentSchool"
    />
    <div v-if="!loaded" class="analytics-paes--loader">
      <LoadingSpinner
        show
        :size="25"
      />
    </div>
    <iframe
      v-if="loaded"
      :key="widget"
      title="AnalyticsPAES"
      class="analytics-paes"
      :src="widget"
      loading="lazy"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingSpinner from '@/components/atoms/loaders/LoaderCircular.vue';
import DashboardNavbar from '@/components/organisms/dashboard/DashboardNavbar.vue';
import CONFIG from '@/config';
import CustomMenu from '../../layouts/CustomMenu.vue';

export default {
  name: 'AnalyticsPAES',
  components: {
    CustomMenu,
    DashboardNavbar,
    LoadingSpinner,
  },
  data() {
    return {
      loaded: true,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    schools() {
      if (!this.currentHeadMasterSchools) {
        return [];
      }
      return this.currentHeadMasterSchools.map((item, index) => ({
        label: `${item.campus_name}`,
        campus_uuid: item.uuid,
        value: index,
      }));
    },
    widget() {
      return `${CONFIG.widgetsURL}/insightsDashboard/${this.campusDetail.institution_code}`;
    },
  },
  methods: {
    ...mapActions({
      getAnalyticsWidget: 'widgets/getAnalyticsWidget',
      setHeadMasterMainSchool: 'roles/setHeadMasterMainSchool',
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
    }),
    setHeadMasterCurrentSchool(index) {
      if (index !== null) {
        this.loaded = false;
        const campusUUID = this.schools[index].campus_uuid;
        if (!('id' in this.currentHeadMasterSchools[index])) {
          this.retrieveCampusDetails({ searchCode: campusUUID }).then((campusDetails) => {
            this.currentHeadMasterSchools[index] = campusDetails;
            this.setHeadMasterMainSchool({ mainSchool: this.currentHeadMasterSchools[index] }).then(
              () => {
                this.loaded = true;
              },
            );
          });
        } else {
          this.setHeadMasterMainSchool({ mainSchool: this.currentHeadMasterSchools[index] }).then(
            () => {
              this.loaded = true;
            },
          );
        }
      }
    },
    setLoading(bool) {
      this.loaded = bool;
    },
  },
};
</script>
